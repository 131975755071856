/// <reference path="../scripts/index.d.ts" />
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
//#############################
var build_pdf_bsk;
var mountHtSlider;
var maxHtSlider;
var detDia;
var dc_frm_select = [];
var arrPrivNotes = [];
var load_mm_tab;

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var arrTechs = [];
  var arrProdSearch = {
    brand: [],
    range: [],
    ip_rating: [],
    sensor_controls: []
  };
  var Sage = {
    // All pages
    'common': {
      init: function () {

        load_mm_tab = function (tab) {
          $('#' + tab).tab('show');
        };

        $(document).on('click', '.yamm .dropdown-menu', function (e) {

          e.stopPropagation();
        });

        $('.menu-search').on('show.bs.dropdown', function () {
          $this = this;
          setTimeout(function () {
            $('input[name="ds"]', $this).get(0).focus();
          }, 500);
        });



        var data, jqxhr;

        // Validate forms
        //  $('#footContModalFrm').validate();
        //  $("#footContFrm").validate();
        //  $("#downloadFRM").validate();
        $.cookieCuttr({
          cookieAnalytics: true,
          cookieAnalyticsMessage: "We use cookies, just to track and manage visits to our website.",
          cookieWhatAreTheyLink: 'http://dextragroup.co.uk/cookie-policy/',
          cookieNotificationLocationBottom: true
        });

        var centre_modal = function ($this) {
          $this.css('display', 'block');
          var $dialog = $this.find(".modal-dialog");
          var offset = (($(window).height() - $dialog.height()) / 2) - 80;

          var bottomMargin = $dialog.css('marginBottom');
          bottomMargin = parseInt(bottomMargin);
          if (offset < bottomMargin) offset = bottomMargin;
          $dialog.css("margin-top", offset);
        };

        //###  Sales Territory contact form
        $("#salesContModalFrm").on('submit', function (e) {
          e.preventDefault();
          //var $this = $(this);
          var data = $(this).serialize() + '&action=submit_sales_contact';
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              if (resp.resp == 'OK') {
                // if ($this.prop('id') == 'footContModalFrm') {
                //$('#footContModalDiv .modal-body')
                //   $('#footContModalDiv').modal('hide');
                // } else {
                $('#salesFormWrap').html('<div class="sales-thanks">Thank you for your enquiry, a member of the team will be in touch soon.</div>');
                //  }
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        });

        // Sales territory modal lookup
        $('#m_sales_terr_search').on('submit', function (e) {
          e.preventDefault();
          var postcode = $('input[name="postcode"]', $(this)).val();
          data = {
            action: 'sales_ajax_query',
            postcode: postcode
          };
          jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(resp);
              if (resp.resp == 'OK') {
                //console.log(resp);
                if (resp.SALES_PEOPLE === '') {
                  $('#sales-team-modal #sales-people').html('<div>NO RESULTS</div>');
                  $('#sales-team-modal .sales-message, #sales-team-modal #related-projects').hide();
                } else {
                  $('#sales-team-modal #sales-people').html(resp.SALES_PEOPLE);
                  $('#sales-team-modal .sales-message, #sales-team-modal #related-projects').show();
                }
                if (resp.SALES_PROJECTS === '') {
                  $('#sales-team-modal #sales-project-wrap').html('');
                  $('#related-projects').hide();
                } else {
                  $('#related-projects').show();
                  $('#sales-team-modal #sales-project-wrap').html(resp.SALES_PROJECTS);
                }
                if (postcode == 'XX' || postcode == 'ZZ') {
                  $('#sales-team-modal .postcode').hide();
                  if (postcode == 'XX')
                    $('#salesContModalFrm input[name="enquiryType"]').val('dexretail');
                  else if (postcode == 'ZZ')
                    $('#salesContModalFrm input[name="enquiryType"]').val('dexeco');

                }
              }
            }, 'json')
            .fail(function () {
              // console.log(jqxhr);
              ajax_fail(jqxhr);
            });

        });

        // New Mega menu parent opening
        // $('#mega-menu').on('click', '[id^=mm-products]', function (e) {
        // 	e.preventDefault();
        //   $('#mega-menu #Products .active a').trigger('click');
        // });

        //##########################################
        // New Mega Menu - Default product category loader, Blogs & Case Studies
        // $('#mega-menu a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        //   var tab = $(e.target).attr('aria-controls');
        //   var data;
        //   console.log(tab); // newly activated tab
        //   if (tab.match(/Products/))
        // 		$('#' + tab + ' li:first-child>a').trigger('click');
        // 	else if (tab.match(/Blog/)) {
        // 		data = {
        // 		  action: 'load_blogs',
        // 		  menu_id: tab,
        // 		  per_page: 4,
        // 		  new_mega: true
        // 		};
        // 		// jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        // 		// 		if (resp.resp == 'OK') {
        // 		// 			$('#mega-menu').find('#'+tab).html(resp.HTML);
        // 		// 		}
        // 		// 	}, 'json')
        // 		// 	.fail(function () {
        // 		// 		ajax_fail(jqxhr);
        // 		// 	});
        // 	}else if (tab.match(/CaseStudies/)) {
        // 		data = {
        // 		  action: 'load_case_studies',
        // 		  menu_id: tab,
        // 		  per_page: 8,
        // 		  new_mega: true
        // 		};
        //   }else if(tab.match(/CaseStudiesGroup/)){
        //     data = {
        // 		  action: 'load_case_studies',
        // 		  menu_id: tab,
        // 		  per_page: 6,
        // 		  new_mega_group: true
        // 		};
        //   }
        //   //console.log(data);
        //   if (data !== undefined) {
        //     jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        //       if (resp.resp == 'OK') {
        //         $('#mega-menu').find('#' + tab).html(resp.HTML);
        //       }
        //     }, 'json')
        //       .fail(function () {
        //         ajax_fail(jqxhr);
        //       });
        //   }
        // 		//$('#mega-menu .side-menu.loader li>a').trigger();
        // 	//e.relatedTarget // previous active tab
        // });

        $(".dropdown-toggle").parent().on('hidden.bs.dropdown', function (e) {
          $('.wrap').css('position', 'static');
          $('.wrap[role="document"] .mmModal').remove();
        });

        //###################################
        //######## New MEGA MENU ###########
        //$('#mega-menu').on('click', '.side-menu.loader li>a:not(.direct-link)', function (e) {
        var load_products = function (menID) {
          //console.log(menID);
          //e.preventDefault();
          $this = $(this);
          //   if(typeof $(this).attr('data-value') != 'undefined') {
          //console.log($this.find('.ubermenu-active').prop('id'));
          // var menID = $this.attr('aria-controls');
          // console.log(menID);
          // if (menID != 'menu-item-13456') {
          data = {
            action: 'load_range',
            range_id: menID,
            new_mega: true
          };
          jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(resp);
              if (resp.resp == 'OK') {
                $('#' + menID).html(resp.HTML);
                //$this.find('.ubermenu-active ul.ubermenu-submenu').html(resp.HTML);
              }
            }, 'json')
            .fail(function () {
              // console.log(jqxhr);
              ajax_fail(jqxhr);
            });
          //     }
          //  }
          //	console.log($(this).attr('data-value'));
        };

        //#  First level nav events
        $('.side-menu.side-menu-small a').on('click', function (e) {
          e.preventDefault();
          if ($(this).parent().hasClass('active') || $(this).hasClass('direct-link')) {
            location.href = $(this).attr('href');
          } else {
            var data = $(this).attr('data-value');
            // Remove any other active tab classes
            $(this).closest('ul').find('li').each(function (index, obj) {
              $(this).removeClass('active');
            });
            $(this).parent().addClass('active');
            console.log(data);
            $('#' + data).parent().children('.tab-pane').removeClass('active');

            $('#' + data).addClass('active');

            var ajxdata = null;
            switch (data) {
              case 'dl-Products':
                if ($('#Amenity').children().length <= 1) {
                  //$('#dl-Products').addClass('active');
                  $('#mm_amenity').parent().addClass('active');
                  load_products('Amenity');
                }
                break;
              case 'de-Products':
                if ($('#LEDProducts').children().length <= 1) {
                  //$('#de-Products').addClass('active');
                  $('#mm_dexled').parent().addClass('active');
                  load_products('LEDProducts');
                }
                break;
              case 'dr-Products':
                if ($('#dr-Downlighters').children().length <= 1) {
                  // $('#dr-Products').addClass('active');
                  $('#mm_downlighters').parent().addClass('active');
                  load_products('dr-Downlighters');
                }
                break;
              case 'dl-CaseStudies':
              case 'de-CaseStudies':
              case 'dr-CaseStudies':
                ajxdata = {
                  action: 'load_case_studies',
                  menu_id: data,
                  per_page: 8,
                  new_mega: true
                };
                break;
                // case 'dl-Technical':
                //   $('#dl-Technical').addClass('active');
                //   break;
            }

            if (ajxdata != null) {
              jqxhr = $.post('/wp-admin/admin-ajax.php', ajxdata, function (resp) {
                  if (resp.resp == 'OK') {
                    $('#mega-menu').find('#' + data + '>div.mm-holder').html(resp.HTML);
                  }
                }, 'json')
                .fail(function () {
                  ajax_fail(jqxhr);
                });
            }
          }
        });

        $('.side-menu.loader a').on('click', function (e) {
          // console.log('second a');
          e.preventDefault();
          var data = $(this).attr('data-value');
          //console.log(data);
          if ($(this).parent().hasClass('active') || data === undefined || data === false) {
            location.href = $(this).attr('href');
          } else {

            $('#' + data).parent().find('.tab-pane').removeClass('active');
            $('#' + data).addClass('active');
            $(this).closest('ul').find('li').each(function (index, obj) {
              $(this).removeClass('active');
            });
            $(this).parent().addClass('active');
            load_products(data);
          }
        });

        $(".dropdown-toggle").on('click', function (e) {
          if ($(this).hasClass('open'))
            location.href = $(this).find('a').attr('href');
        });

        // ##### Auto triggering of default product lazy load in new mega-menu
        $(".dropdown-toggle").parent().on('shown.bs.dropdown', function (e) {
          //console.log($('#Amenity').children());
          if ($(this).hasClass('menu-dextra-lighting')) {
            if ($('#Amenity').children().length <= 1) {
              $('#dl-Products, #mm-products-lighting').addClass('active');
              $('#mm_amenity').parent().addClass('active');
              load_products('Amenity');
            }
          } else if ($(this).hasClass('menu-dexeco')) {
            if ($('#LEDProducts').children().length <= 1) {
              $('#de-Products, #mm-products-eco').addClass('active');
              $('#mm_dexled').parent().addClass('active');
              load_products('LEDProducts');
            }
          } else if ($(this).hasClass('menu-dexretail')) {
            if ($('#dr-Downlighters').children().length <= 1) {
              $('#dr-Products, #mm_downlighters').parent().addClass('active');
              $('#mm_downlighters').parent().addClass('active');
              load_products('dr-Downlighters');
            }
          }

          if ($(this).parent().prop('id') == 'mega-menu')
            $('.wrap[role="document"]').css('position', 'relative').prepend('<div class="mmModal"></div>');
          $('.mmModal').height($(document).height());
        });


        // Uber menu Ajax lazy loading - Products
        // $( '#menu-item-8795, #menu-item-8809, #menu-item-8387' ).on( 'ubermenuopen', function(e){
        // 	 console.log('doin');
        // 	$this = $(this);
        // 	//console.log($this.find('.ubermenu-active').prop('id'));
        // 	var menID = $this.find('.ubermenu-active').prop('id');
        // 	if(menID != 'menu-item-13456') {
        // 		data = {action: 'load_range', range_id: menID};
        // 		jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        // 			//console.log(resp);
        // 			if (resp.resp == 'OK') {
        // 				$this.find('.ubermenu-active ul.ubermenu-submenu').html(resp.HTML);
        // 			}
        // 		}, 'json')
        // 			.fail(function () {
        // 				// console.log(jqxhr);
        // 				ajax_fail(jqxhr);
        // 			});
        // 	}
        // });

        // Uber menu Ajax lazy loading - Blogs
        // $('#menu-item-8456, #menu-item-8797, #menu-item-8812, #menu-item-8837').on('ubermenuopen', function (e) {
        //   console.log('auto');
        // 		var $this = $(this);
        // 		var ExHTML = $this.find('ul.ubermenu-submenu .row').html();  // Finds the extra Uber custom content - button
        // 		ExHTML = ExHTML === undefined ? '' : ExHTML;
        // 		data = {action: 'load_blogs', menu_id: $this.prop('id'), per_page : 4};
        // 		jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        // 				if (resp.resp == 'OK') {
        // 					$this.find('ul.ubermenu-submenu').html(resp.HTML+ExHTML);
        // 				}
        // 			}, 'json')
        // 			.fail(function () {
        // 				ajax_fail(jqxhr);
        // 			});
        // });

        // Uber menu Ajax lazy loading - Case Studies
        // $( '#menu-item-8790, #menu-item-8796, #menu-item-8810, #menu-item-8836' ).on( 'ubermenuopen', function(e){
        // 	var $this = $(this);
        // 	var ExHTML = $this.find('ul.ubermenu-submenu .row').html();  // Finds the extra Uber custom content - button
        // 	ExHTML = ExHTML === undefined ? '' : ExHTML;
        // 	data = {action: 'load_case_studies', menu_id: $this.prop('id'), per_page : 8};
        // 	jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        // 			if (resp.resp == 'OK') {
        // 				$this.find('ul.ubermenu-submenu').html(resp.HTML+ExHTML);
        // 			}
        // 		}, 'json')
        // 		.fail(function () {
        // 			ajax_fail(jqxhr);
        // 		});
        // });

        //console.log(window.location.pathname);
        var arrPath = window.location.pathname.split('/');

        //console.log(arrPath);

        $('.menu-' + arrPath[1]).addClass('ubermenu-active-g');
        $('.menu-' + arrPath[2]).addClass('ubermenu-active-g');

        // Product download forms
        $("#downloadFRM").submit(function (e) {
          e.preventDefault();
          data = $(this).serialize() + '&action=submit_download_frm';
          jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              if (resp.resp == 'OK') {
                $('#mDownload').modal('hide');
                $('#mDownload').find('form')[0].reset();
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        });

        // Contact forms
        $("#footContFrm, #footContModalFrm").submit(function (e) {
          e.preventDefault();
          var $this = $(this);
          var data = $(this).serialize() + '&action=submit_footer_contact';
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
          if (resp.resp == 'OK') {
            var HTML;
              if ($this.prop('id') == 'footContModalFrm') {
                HTML = $('#footContModalDiv .modal-body').html();
                $('#footContModalDiv .modal-body').html('<div class="thanks">Thank you for your enquiry, a member of the team will be in touch soon.</div>');
                setTimeout(function () {
                  $('#footContModalDiv').modal('hide');
                  $('#footContModalDiv .modal-body').html(HTML);
                  $("#footContModalFrm")[0].reset();
                }, 2000);
              } else {
                HTML = $('#footContWrap').html();
                $('#footContWrap').fadeOut(function () {
                  $('#footContWrap').html('<div class="thanks">Thank you for your enquiry, a member of the team will be in touch soon.</div>');
                  $('#footContWrap').fadeIn();
                });

                setTimeout(function () {
                  $('#footContWrap').fadeOut(function () {
                    $('#footContWrap').html(HTML);
                    $("#footContFrm")[0].reset();
                    $('#footContWrap').fadeIn();
                  });
                }, 3000);
              }
            }
          }, "json")
          .fail(function () {
            ajax_fail(jqxhr);
          });
          //console.log(data);
        });

        // Contact forms
        $("#eventFrm").submit(function (e) {
          e.preventDefault();
          var $this = $(this);
          console.log("W");
          var data = $(this).serialize() + '&action=submit_event';
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              if (resp.resp == 'OK') {
                $('#event-book-wrap').html('<div>Thank you for your enquiry, a member of the team will be in touch soon.</div>');
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
          //console.log(data);
        });

        //console.log($("#footContFrm"));


        $('.panel-heading').click(function () {
          if ($(this).hasClass('activestate')) {
            $(this).removeClass('activestate');
          } else {
            $('.panel-heading').removeClass('activestate');
            $(this).addClass('activestate');
          }
        });

        $('#ubermenu-nav-main-298').prepend('<li class="tel">(+44) 01747 858100</li>');

        //########   Product overview  ############

        /*   $('#products, #related-products').on('click', '.product-overview', function(e){
         var mlink = $(this);
         if (mlink.hasClass('hover'))
         return true;
         else {
         mlink.addClass('hover');
         $('#products .product-overview, #related-products .product-overview').not(mlink).removeClass('hover');
         }
         });
         */

        $('#products, #case-studies, #case-studies-xyz, #projects, #related-projects, #related-prods-xyz, #related-prods, #related-products, #sensors, #sensors-xyz').on("touchhold", '.product-overview', function (e) {
          //console.log(e);
          e.preventDefault();
        });

        $('#products, #case-studies, #case-studies-xyz, #projects, #related-projects, #related-prods, #related-products, #related-prods-xyz, #sensors, #sensors-xyz').on('touchend touchcancel mouseover', '.product-overview', function (e) {
          //console.log(e);
          $e = e;
          mlink = $(this);
          if (mlink.hasClass('hover')) {
            return true;
          } else {
            mlink.addClass('hover');
            $('#projects .product-overview, #related-projects .product-overview, #case-studies .product-overview, #case-studies-xyz .product-overview, #products .product-overview, #related-prods-xyz .product-overview, #related-prods .product-overview, #related-products .product-overview, #sensors .product-overview, #sensors-xyz .product-overview').not(mlink).removeClass('hover');
            $('#projects .product-overview .hidden .btn, #related-projects .product-overview .hidden .btn, #case-studies .product-overview .hidden .btn, #case-studies-xyz .product-overview .hidden .btn, #products .product-overview .hidden .btn, #related-prods-xyz .product-overview .hidden .btn, #related-prods .product-overview .hidden .btn, #related-products .product-overview .hidden .btn, #sensors .product-overview .hidden .btn,  #sensors-xyz .product-overview .hidden .btn').not(mlink).removeClass('shown');
            timeoutId = setTimeout(function () {
              $('.btn', mlink).addClass('shown');
            }, 200);

          }

          if (!$('.btn', mlink).hasClass('shown'))
            e.preventDefault();
        });

        $('#products, #projects, #related-projects, #case-studies, #case-studies-xyz, #related-prods-xyz, #related-prods, #related-products, #sensors, #sensors-xyz').on("mouseout", '.product-overview', function (e) {
          //console.log(e);
          $('#projects .product-overview, #related-projects .product-overview, #case-studies .product-overview, #case-studies-xyz .product-overview, #products .product-overview, #related-prods-xyz .product-overview, #related-prods .product-overview, #related-products .product-overview, #sensors .product-overview, #sensors-xyz .product-overview').removeClass('hover');
          $('#projects .product-overview .hidden .btn, #related-projects .product-overview .hidden .btn,#case-studies .product-overview .hidden .btn, #case-studies-xyz .product-overview .hidden .btn, #products .product-overview .hidden .btn, #related-prods-xyz .product-overview .hidden .btn, #related-prods .product-overview .hidden .btn, #related-products .product-overview .hidden .btn, #sensors .product-overview .hidden .btn, #sensors-xyz .product-overview .hidden .btn').removeClass('shown');
        });


        /*  $('#products, #related-products').on('click, mouseover', '.product-overview', function(e){
         //console.log(e);
         if($(e.target)[0].className == 'img-responsive') {
         e.preventDefault();
         $('#products .product-overview .hidden, #related-products .product-overview .hidden').removeClass('active');
         $(this).find('.hidden').addClass('active');
         }
         });*/

        //######  PDF enquiry form  ##########
        $('#pdfBasket').on('click', '#btnSubEnc', function () {
          $('#pdfEncForm').slideDown('slow', function () {
            $("html, body").animate({
              scrollTop: ($('#pdfEncForm').offset().top - $(window).height()) + $('#pdfEncForm').height()
            }, 1000);
          });
          $('#btnSubEnc').fadeOut('slow');
        });

        //Submit PDF enquiry form
        $('#pdf_submit').on('click', function (e) {
          e.preventDefault();
          data = $(this).closest('form').serialize() + '&action=submit_pdf_basket'; //{action: 'submit_pdf_basket'};
          jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              if (resp.resp == 'OK') {
                build_pdf_bsk();
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        });

        $('.btn-step').click(function (e) {
          e.preventDefault();
          //console.log($("#step_file").offset().top);
          //	$('html, body').animate({
          //		scrollTop: $("#step_file").offset().top -60
          //	}, 1000, function(){
          $('#prodTabs a[href="#step_file"]').tab('show');
          //	});

        });

        // #### STEP FILE TAB scroller ###
        // No longer required
        /*
				$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
					if($(e.target).prop('id') == 'step_tab'){
						$('html, body').animate({
							scrollTop: $("#step_file").offset().top -60
						}, 1000);
					}
				});
*/
        // Handle various modals
        $('.modal').on('show.bs.modal', function (e) {
          var $this = $(this);
          if (!$(this).is(':visible')) {

            // ##### DOWNLOAD FORM MODALS  #####
            if (e.currentTarget.id == 'mDownload') {
              var attrData = $(e.relatedTarget).attr('data-value').split(':');
              $this.find('#myModalLabel').text(attrData[1]);
              $this.find('#downType').val(attrData[0]);
              $this.find('#downTypeTitle').val(attrData[1]);
            }

            //  STEP file modal
            if (e.currentTarget.id == 'stepfile') {
              //console.log($this.find('#stepFrm'));

              var step_id = $(e.relatedTarget).attr('data-value');

              //	console.log(step_id);

              // Get the step thumbnail from the ID
              data = {
                step_code: step_id,
                action: 'get_step_url'
              };
              jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                  //	console.log(resp.URL);
                  if (resp.resp == 'OK') {
                    //		console.log(resp);
                    var stepFrm = $this.find('#stepFrm');
                    if (stepFrm.attr('src') === '' || stepFrm.attr('src') !== resp.URL)
                      $this.find('#stepFrm').attr('src', resp.URL);
                  }
                }, "json")
                .fail(function () {
                  ajax_fail(jqxhr);
                });

            }



            // ######  QUICK VIEW MODAL  #########
            if ($(e.relatedTarget).hasClass('overview')) {
              data = {
                action: 'quick_view_content',
                'postID': $(e.relatedTarget).attr('data-value'),
                is_sensor: $(e.relatedTarget).hasClass('overview-sensors')
              };
              console.log(data);
              jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                  if (resp.resp == 'OK')
                    $('#quickViewContent').html(resp.HTML);
                }, "json")
                .complete(function () {
                  centre_modal($this);
                })
                .fail(function () {
                  ajax_fail(jqxhr);
                });
            } else {
              centre_modal($this);
            }
          }

        });


        $('.modal').on('shown.bs.modal', function (e) {

          // ######  CONTACT FROM MODAL  #######
          if (e.currentTarget.id == 'footContModalDiv')
            $(this).find('#dcomp').val($(e.relatedTarget).attr('data-value'));

          // Crap bug fix for shiftnav plugin launching extra modal!
          $('.modal-backdrop').remove();
        });



        $('.modal').on('hidden.bs.modal', function (e) {
          $('#quickViewContent').html('');
          // Product Video modal
          if (e.currentTarget.id == 'prod_video') {
            var source = $('iframe#prodVimvid').attr('src');
            $('iframe#prodVimvid').attr('src', '');
            $('iframe#prodVimvid').attr('src', source);
          }
        });

        $('.ubermenu-search-input, .shiftnav-search-input').attr('name', 'ds');
        $('.ubermenu-search-input, .shiftnav-search-input').val($.query.get('ds'));

        // Product ranges sorting
        if ($('#noJquerySort').length < 1)
          $('#products').mixItUp();
        else
          $('#products .mix, .category-sort').css('display', 'block');
        var found = 0;
        var classes = ['.category-sort-hid', '.category-sort-led', '.category-sort-t5', '.category-sort-t8'];
        $.each(classes, function (index, val) {
          if ($('#products ' + val).length < 1)
            $("a[data-filter='" + val + "']").hide();
          else
            found++;
          if (found < 2 && $.query.get('cfs') === '')
            $('.category-sort').hide();
          else
            $('.category-sort').show();
        });

        // ####  Project by company sorting
        if ($('#noJquerySort').length < 1)
          $('#projects').mixItUp();
        else
          $('#projects .mix, .category-sort').css('display', 'block');
        var pj_found = 0;
        var pj_classes = ['.type-dextra-lighting', '.type-dexretail', '.type-dexeco'];
        $.each(pj_classes, function (index, val) {
          if ($('#projects ' + val).length < 1)
            $("a[data-filter='" + val + "']").hide();
          else
            pj_found++;
          if (pj_found < 2 && $.query.get('pfs') === '')
            $('.category-sort').hide();
          else
            $('.category-sort').show();
        });

        //open the lateral panel
        $('#pdfBskBtn').on('click', function (event) {
          event.preventDefault();
          $('.cd-panel').addClass('is-visible');
        });
        //close the lateral panel
        $('.cd-panel').on('click', function (event) {
          if ($(event.target).is('.cd-panel') || $(event.target).is('.cd-panel-close')) {
            $('.cd-panel').removeClass('is-visible');
            setTimeout(function () {
              $('#pdfEncForm').hide();
              $('#btnSubEnc').show();
            }, 1000);
            event.preventDefault();
          }
        });



        //#########  ADD TO PDF BASKET BUTTON  #######
        $('#related-prods, #products, #product').on('click', '#addToBsk, .addToBsk', function (e) {
          e.preventDefault();
          var data = {
            action: 'add_to_pdf_basket',
            'postData': $(this).attr('data-value')
          };
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(resp);
              if (resp.resp == 'OK') {
                build_pdf_bsk(resp.pdfBsk);
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
          $('#pdfBskBtn').trigger('click');
        });

        $('#pdfBasket').on('click', '.bskRm', function () {
          //console.log($(this).attr('data-value'));
          var data = {
            action: 'remove_from_pdf_basket',
            'rmPDF': $(this).attr('data-value')
          };
          var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(resp);
              if (resp.resp == 'OK') {
                build_pdf_bsk();
              }
            }, "json")
            .fail(function () {
              ajax_fail(jqxhr);
            });
        });

        build_pdf_bsk();

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'case_studies': { // ###############  CASE STUDIES  ########
      init: function () {
        $(window).scroll(function () {
          // (location.pathname+location.search).substr(1)
          //console.log($(window).scrollTop()+' == '+($(document).height() - $(window).height())+' < '+$('#footer').height());
          var end = $("#footer").offset().top;
          var viewEnd = $(window).scrollTop() + $(window).height();
          var distance = end - viewEnd;

          // console.log(rec_count, total_recs, end, viewEnd, distance);
          if (distance < -50 && !psloading && rec_count < total_recs) { // do load
            psloading = true;
            $('.category-intro').append('<div id="psloading"><img src="/wp-content/themes/dextra/dist/images/global/loading.gif" /></div>');
            var data = {
              action: 'get_case_studies_lazy',
              rec_count: rec_count,
              cat: cat
            };
            var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                // console.log(rec_count, resp);
                if (resp.resp == 'OK') {
                  $('#psloading').remove();
                  psloading = false;
                  rec_count = rec_count + resp.rec_count;
                  $('.category-intro').append($('<div />').html(resp.HTML).hide().fadeIn(600, function () {
                    //console.log($(document).height());
                    // $('.mmModal').height($(document).height());
                  }));
                  //console.log(rec_count);
                }
              }, "json")
              .fail(function () {
                ajax_fail(jqxhr);
              });
            //if($(window).scrollTop() == ($(document).height() - $(window).height())) {
            // ajax call get data from server and append to the div
            // console.log('www');
            // new_element.hide().appendTo('.your_div').fadeIn();
            // $(window).scrollTop($(window).scrollTop() - 1);
          }
        });
      }
    },

    'single_projects': { // ###############  PROJECTS ########
      init: function () {
				$('.slider-for').slick({
				  slidesToShow: 1,
				  slidesToScroll: 1,
				  arrows: false,
				  fade: true,
				  adaptiveHeight: true,
				  asNavFor: '.slider-nav',
				  responsive: [
				    {
				      breakpoint: 992,
				      settings: {
							  arrows: true,
							  dots: false
				      }
				    }
				  ]
				});
				$('.slider-nav').slick({
				  slidesToShow: 5,
				  slidesToScroll: 1,
				  asNavFor: '.slider-for',
				  dots: false,
				  arrows: true,
				  centerMode: true,
				  focusOnSelect: true,
				  responsive: [
				    {
				      breakpoint: 992,
				      settings: {
							  slidesToShow: 3,
				      }
				    },
				    {
				      breakpoint: 768,
				      settings: {
							  slidesToShow: 2,
				      }
				    },
				    {
				      breakpoint: 550,
				      settings: {
							  slidesToShow: 1,
				      }
				    }
				  ]
				});
      }
    },
    //#############  NEWS  ###############
    'blog': {
      init: function () {
        $(window).scroll(function () {
          // (location.pathname+location.search).substr(1)
          //console.log($(window).scrollTop()+' == '+($(document).height() - $(window).height())+' < '+$('#footer').height());
          var end = $("#footer").offset().top;
          var viewEnd = $(window).scrollTop() + $(window).height();
          var distance = end - viewEnd;

          //console.log(rec_count, total_recs, end, viewEnd, distance);
          if (distance < -50 && !psloading && rec_count < total_recs) { // do load
            psloading = true;
            $('.category-intro').append('<div id="psloading"><img src="/wp-content/themes/dextra/dist/images/global/loading.gif" /></div>');
            var data = {
              action: 'get_news_lazy',
              rec_count: rec_count
            };
            var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                //  console.log(rec_count, resp);
                if (resp.resp == 'OK') {
                  $('#psloading').remove();
                  psloading = false;
                  rec_count = rec_count + resp.rec_count;
                  $('.category-intro').append($('<div />').html(resp.HTML).hide().fadeIn(600, function () {
                    //console.log($(document).height());
                    // $('.mmModal').height($(document).height());
                  }));
                  //console.log(rec_count);
                }
              }, "json")
              .fail(function () {
                ajax_fail(jqxhr);
              });
            //if($(window).scrollTop() == ($(document).height() - $(window).height())) {
            // ajax call get data from server and append to the div
            // console.log('www');
            // new_element.hide().appendTo('.your_div').fadeIn();
            // $(window).scrollTop($(window).scrollTop() - 1);
          }
        });

      }
    },
    //##############  PROCUCT SEARCH  ##################
    'products': {
      init: function () {

        var removeItemRange = false;
        var removeItemBrand = false;
        var removeItemIPRating = false;
        var removeItemControl = false;

        $('#sharedProducts').on('show.bs.modal', function (e) {
          var arrData = e.relatedTarget.attr('data-value').split(':');
          $('#psModalTitle').text(arrData[0]);
          var arrBrands = arrData[1].split(',');
          var arrNames = arrData[2].split(',');
          $('#psbrands li').hide();
          for (var x = 0; x < arrBrands.length; x++) {
            $('#psbrands .' + arrBrands[x]).css('display', 'inline-block');
            var existHref = $('li.' + arrBrands[x] + ' a', this).attr('href');
            $('li.' + arrBrands[x] + ' a', this).attr('href', existHref + '/' + arrNames[x]);
          }
        });

        $('#products').on('click', 'a.psmodal', function (e) {
          e.preventDefault();
          //$('#sharedProducts').modal('toggle', $(this));
          var arrData = $(this).attr('data-value').split(':');
          var arrBrands = arrData[1].split(',');
          var arrNames = arrData[2].split(',');
          //$('#psbrands li').hide();
          console.log(arrBrands.indexOf('dexeco'));
          if (arrBrands.indexOf('dextra-lighting') != -1)
            location.href = '/dextra-lighting/' + arrNames[arrBrands.indexOf('dextra-lighting')];
          else if (arrBrands.indexOf('dexeco') != -1)
            location.href = '/dexeco/' + arrNames[arrBrands.indexOf('dexeco')];
          else if (arrBrands.indexOf('dexretail') != -1)
            location.href = '/dexretail/' + arrNames[arrBrands.indexOf('dexretail')];
          else if (arrBrands.indexOf('led-extra') != -1)
            location.href = '/led-extra/' + arrNames[arrBrands.indexOf('led-extra')];
          // for (var x = 0; x < arrBrands.length; x++){
          //   //$('#psbrands .' + arrBrands[x]).css('display', 'inline-block');

          //   var existHref = $('li.' + arrBrands[x]+' a', this).attr('href');
          //   $('li.'+arrBrands[x]+' a', this).attr('href', existHref + '/' + arrNames[x]);
          // }
        });

        $(window).scroll(function () {
          // (location.pathname+location.search).substr(1)
          //console.log($(window).scrollTop()+' == '+($(document).height() - $(window).height())+' < '+$('#footer').height());
          var end = $("#footer").offset().top;
          var viewEnd = $(window).scrollTop() + $(window).height();
          var distance = end - viewEnd;
          //console.log(rec_count, total_recs);
          if (distance < +250 && !psloading && rec_count < total_recs) { // do load
            psloading = true;
            $('.category-intro').append('<div id="psloading"><img src="/wp-content/themes/dextra/dist/images/global/loading.gif" /></div>');
            var data = {
              action: 'get_search_content_lazy',
              'path': location.pathname,
              query: (location.search).substr(1),
              rec_count: rec_count
            };
            var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                console.log(rec_count, resp.rec_count);
                if (resp.resp == 'OK') {
                  $('#psloading').remove();
                  psloading = false;
                  rec_count = rec_count + resp.rec_count;
                  $('.category-intro').append($('<div />').html(resp.HTML).hide().fadeIn(600, function () {
                    //console.log($(document).height());
                    $('.mmModal').height($(document).height());
                  }));
                  //console.log(rec_count);
                }
              }, "json")
              .fail(function () {
                ajax_fail(jqxhr);
              });
            //if($(window).scrollTop() == ($(document).height() - $(window).height())) {
            // ajax call get data from server and append to the div
            // console.log('www');
            // new_element.hide().appendTo('.your_div').fadeIn();
            // $(window).scrollTop($(window).scrollTop() - 1);
          }
        });

        $('#products a.remSearch').click(function (e) {
          e.preventDefault();
          var arrData = $(this).attr('data-value').split(':');
          var item = null;
          if (arrData[0] == 'range') {
            //$('#range_'+arrData[1]).val('');
            removeItemRange = true;
            item = 'range_' + arrData[1];
          }
          if (arrData[0] == 'brand') {
            //$('#range_'+arrData[1]).val('');
            removeItemBrand = true;
            item = 'brand_' + arrData[1];
          }
          if (arrData[0] == 'ip_rating') {
            //$('#range_'+arrData[1]).val('');
            removeItemIPRating = true;
            item = 'ip_rating_' + arrData[1];
          }
          if (arrData[0] == 'sensor_controls') {
            //$('#range_'+arrData[1]).val('');
            removeItemControl = true;
            item = 'sensor_controls_' + arrData[1];
          }
          console.log(item);
          $('#' + item).trigger('change');
        });

        // console.log(location.search);
        $('input.prs:checkbox').change(function (e) {
          //console.log($(this));
          $this = $(this);
          $thisArrVals = $this.val().split(':');
          // return false;
          var strQstring = '';
          var arrVals;
          var strRange = '/products/';
          var selRange = '';
          if ($(this).is(':checked') && $thisArrVals[0] == 'range' && !removeItemRange) {
            strRange += $thisArrVals[1] + '/';
            selRange = $thisArrVals[1];
          }
          var arrQVals = [];
          $('input.prs:checkbox').each(function () {
            arrVals = $(this).val().split(':');
            //console.log(arrVals[1] + ' ' + $(this).is(':checked'));
            //if ((arrVals[0] == 'range' && $this.is(':checked') && $this.val() == $(this).val() && !removeItemRange) ||
            // (arrVals[0] == 'range' && $(this).is(':checked') && $thisArrVals[0] != 'range')) {
            //  console.log(arrVals[1]+' '+$this.is(':checked'));
            // strRange += arrVals[1] + '/';
            // console.log(strRange);
            //} else {
            // console.log($thisArrVals);
            // if (arrVals[0] == 'range' && $thisArrVals[0] == 'range' && !$this.is(':checked')) {
            if (arrVals[0] == 'range' && selRange != arrVals[1] && !$(this).is(':checked'))
              $(this).prop('checked', false);
            else if (arrVals[0] == 'range' && selRange == '' && $(this).is(':checked') && !removeItemRange) {
              console.log('eree');
              strRange += arrVals[1] + '/';
            }
            //  strRange = '/products/';
            //  if(arrVals[1] == 'amenity')
            //  console.log($(this));
            //}
            if (($(this).is(':checked') && arrVals[0] != 'range') &&
              (((arrVals[0] == 'brand' && !removeItemBrand) || $this.val() != $(this).val()) ||
              ((arrVals[0] == 'ip_rating' && !removeItemIPRating) || $this.val() != $(this).val()) ||
              ((arrVals[0] == 'sensor_controls' && !removeItemControl) || $this.val() != $(this).val())
            )
            ) {
              if (arrProdSearch[arrVals[0]].indexOf(arrVals[1]) == -1) {
                arrProdSearch[arrVals[0]].push(arrVals[1]);
              }
            } else { //} if($this.val() == $(this).val()) {
              var index = arrProdSearch[arrVals[0]].indexOf(arrVals[1]);
              if (index !== -1) arrProdSearch[arrVals[0]].splice(index, 1);
            }
            //}
          });

          removeItemRange = false;
          removeItemBrand = false;
          removeItemIPRating = false;
          removeItemControl = false;

          // Build query string
          for (var obj in arrProdSearch) {
            if (arrProdSearch[obj].length) {
              arrQVals.push(obj + '=' + arrProdSearch[obj].join(','));
            }
            strQstring = arrQVals.join('&');
            strQstring = strQstring != '' ? '?' + strQstring : strQstring;
          }
          location.href = strRange + strQstring;
          //console.log(strRange + strQstring);
        });
      }
    },
    'tax_sensors': {
      init: function () {
        $('.senCatCB').click(function () {
          //console.log($(this).val());
          //	$(this).prop('checked', true);
          location.href = '/sensors/lighting-controls/' + $(this).val();
        });
        //minHtSlider.on('slide', function(slide){
        //		console.log(slide);
        //});
        $('.senTermCB').click(function () {
          //console.log($(this).is(':checked'));
          var arrData = $(this).val().split(':');
          var taxonomy = arrData[0] !== '' ? arrData[0] + '/' : '';
          //console.log(arrData);
          if ($(this).is(':checked')) {
            //console.log(location.search);
            if (location.search === '' && !/cfs/.test(location.search))
              location.href = '/sensors/lighting-controls/' + taxonomy + '?cfs[]=' + arrData[1] + ':' + arrData[2];
            else
              location.href = '/sensors/lighting-controls/' + taxonomy + location.search + '&cfs[]=' + arrData[1] + ':' + arrData[2];
          } else {
            var re;
            if (arrData[2] === 1)
              re = new RegExp('&?cfs\\[\\]=' + arrData[1] + ':1');
            else
              re = new RegExp('&?cfs\\[\\]=' + arrData[1] + ':' + arrData[2]);
            //console.log(arrData[1]+':'+arrData[2]);
            var qString = location.search.replace(re, '');
            location.href = '/sensors/lighting-controls/' + taxonomy + qString;
          }
        });
      }
    },
    // Home page
    'home': {
      init: function () {
        $('.next').on('click', function () {
          var target = $(this.hash);
          target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top
            }, 500);
            return false;
          }
        });
        /*  var wHeight = $(window).height();
         $('.colour')
         .scrollie({
         scrollOffset : 0,
         scrollingInView : function(elem) {
         var bgColor = elem.data('background');
         $('body').css('background-color', bgColor);
         }
         });
         */
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'dexsor': {
      init: function () {
        $('.technical .dzoom').each(function (index, obj) {
          $(this).parent().zoom({
            on: 'grab',
            url: this.src.replace('_th', '')
            // url: this.src.replace('-400x400', '')
          });
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'sales_territory': {
      init: function () {
        // JavaScript to be fired on the about us page
        if ($('#saleslookup input[name="enquiryType"]').length) {
          if ($('#saleslookup input[name="enquiryType"]').val() == 'dexretail') {
            $('#saleslookup input[name="postcode"]').val('XX');
            $('#saleslookup button[name="submt"]').trigger('click');

          } else if ($('#saleslookup input[name="enquiryType"]').val() == 'dexeco') {
            $('#saleslookup input[name="postcode"]').val('ZZ');
            $('#saleslookup button[name="submt"]').trigger('click');

          }
        }

      }
    },
    'single': {
      init: function () {

        if ($('body').hasClass('single-dexretail')) {
          $('.modal').on('show.bs.modal', function (e) {
            var $this = $(this);
            if (e.currentTarget.id == 'sales-team-modal') {
              $('#m_sales_terr_search #postcode').val('XX');
              $('#m_sales_terr_search').submit();
            }
          });
        } else if ($('body').hasClass('single-dexeco')) {
          $('.modal').on('show.bs.modal', function (e) {
            var $this = $(this);
            if (e.currentTarget.id == 'sales-team-modal') {
              $('#m_sales_terr_search #postcode').val('ZZ');
              $('#m_sales_terr_search').submit();
            }
          });
        }

        //	$('.dtooltip').on('show.bs.tooltip', function (e) {
        //		console.log(e);
        //	});

        var data, jqxhr;

        //##############################################
        function searchHTML(searchString, htmlString, descr) {
          //console.log(searchString);
          searchString = searchString.replace('(', '\\(');
          searchString = searchString.replace(')', '\\)');
          //console.log(searchString);
          var expr = new RegExp('\\b' + searchString + '(?:[ \n\r\t.,\'\"\+!?-]+\|$)', "gim");
          var container = $("<div>").html(htmlString);

          var elements = container.find(':not(.nogloss):not(.nogloss *)');
          //var elements = $(":not(* .nogloss)", container);

          var textNodes = elements.contents().not(elements);
          //console.log(textNodes);
          textNodes.each(function () {
            //console.log(this.length);console.log(this);
            if (this.length > 1) {
              //console.log(this.nodeValue);
              var matches = this.nodeValue.match(expr);
              //console.log(matches);
              if (matches) {
                var parts = this.nodeValue.split(expr);
                //console.log(parts);
                for (var n = 0; n < parts.length; n++) {
                  // if (n) {
                  // 	container.find(this).before($('<span class="dtooltip">').text(matches[n - 1]).attr({'data-toggle':"tooltip", 'data-placement':"top", 'title':descr}));
                  // 	//console.log(container);
                  // }
                  if (parts[n]) {
                    //console.log(this);
                    container.find(this).before($(document.createTextNode(parts[n])));
                    //console.log(container);
                  }
                }
                //console.log(container);
                container.find(this).remove();
              }
            }
          });
          //	console.log(container);
          return container.html();
        }

        //####################################
        var glossary = function (content, callback) {
          // Glossary
          // AJAX request to get all keywords
          data = {
            action: 'acf_get_option_group',
            optName: 'glossary',
            optSubKey: 'phrase',
            stripHTML: true
          };
          jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
              //console.log(content);
              if (resp.resp == 'OK') {
                //	console.log(resp.optGroup);
                //return;
                //var content = $('#product .tab-content').html();
                $.each(resp.optGroup, function (index, val) {
                  //var re = new RegExp('<p>(<!<[^>]*)('+val+')(<![^>]*<)<\/p>', "gim");
                  //$('#product .tab-content').html(content.replace(re, '<span style="color:red;">$2</span>'));

                  //content = replaceWords(content, [val]);
                  //console.log(val);
                  content = searchHTML(val.phrase, content, val.description);
                  //	$('#product .tab-content').html(content);
                });
                callback(content);
                //	console.log(content);
              }
            }, 'json')
            .fail(function () {
              // console.log(jqxhr);
              ajax_fail(jqxhr);
            });
        };

        // Product tab switching
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          //console.log("W"+$(e.currentTarget.hash).html()+"E");
          var company = $(e.currentTarget.hash).attr('data-value');
          if ($(e.currentTarget.hash + ' .tab.loader').length) {
            data = {
              company: company,
              action: 'load_tab',
              'postID': $('#postID').val(),
              tab_type: e.currentTarget.hash
            };
            jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                //console.log(resp.resp);
                if (resp.resp == 'OK') {
                  var content = resp.HTML;
                  //console.log(resp);
                  // if(e.currentTarget.hash === '#specification' ||
                  // 	 e.currentTarget.hash === '#warranty' ||
                  // 	 e.currentTarget.hash === '#led-benefits' ||
                  // 	 e.currentTarget.hash === '#ordering' ||
                  // 	 e.currentTarget.hash === '#related-articles') {
                  // 	glossary(content, function(cont){
                  // 		$(e.currentTarget.hash).html(cont);
                  // 		$('[data-toggle="tooltip"]').tooltip();
                  // 	});

                  // }else{
                  $(e.currentTarget.hash).html(content);
                  //}

                  $('#technical .tech').each(function (index, obj) {
                    $(this).parent().zoom({
                      on: 'grab',
                      callback: function () {
                        //  var techImg = $(this).parent().find('.tech')[0].currentSrc;
                        var techImg = this.src;
                        if ($.inArray(techImg, arrTechs) == -1)
                          $(this).attr('src', techImg.replace('_thumb.jpg', '.png'));
                        arrTechs.push(techImg);
                      }
                    });
                  });
                }
              }, "json")
              .fail(function () {
                ajax_fail(jqxhr);
              });
          }
          //var $this = $(this);
          //		if (!$(this).is(':visible')) {
          //console.log(e.currentTarget.hash);
          // ##### DOWNLOAD FORM MODALS  #####
          //	if (e.currentTarget.id == 'mDownload') {

          //}
          //	}
        });

        // ##########  INTRODUCTION TAB  ##########
        data = {
          action: 'load_tab',
          'postID': $('#postID').val(),
          tab_type: '#introduction'
        };
        jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
            //console.log(resp.HTML);
            if (resp.resp == 'OK') {
              glossary(resp.HTML, function (cont) {
                $('#introduction').html(cont);
                $('#introduction-xyz').html(cont);
                //	$('[data-toggle="tooltip"]').tooltip();
              });
            }
          }, "json")
          .fail(function () {
            ajax_fail(jqxhr);
          });

        //######  ACCORDION  ############
        $('#accordion').on('shown.bs.collapse', function (e) {
          //console.log(e.target.id);
          var company = $('#' + e.target.id).attr('data-value');

          // Scroll it
          $('html, body').animate({
            scrollTop: $('#' + e.target.id).offset().top - 90
          }, 1000, function () {});

          //console.log(company);
          if ($('#' + e.target.id + ' .tab.loader').length) {
            //console.log('ere');
            data = {
              company: company,
              action: 'load_tab',
              'postID': $('#postID').val(),
              tab_type: (('#' + e.target.id).replace(/\-xyz$/, ''))
            };
            //console.log(data);
            jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
                //console.log(resp.resp);
                if (resp.resp == 'OK') {
                  var content = resp.HTML;

                  //console.log(resp);
                  if (e.target.id === 'specification-xyz' ||
                    e.target.id === 'warranty-xyz' ||
                    e.target.id === 'led-benefits-xyz' ||
                    e.target.id === 'ordering-xyz' ||
                    e.target.id === 'related-articles-xyz') {
                    glossary(content, function (cont) {
                      $('#' + e.target.id).html(cont);
                      //	$('[data-toggle="tooltip"]').tooltip();
                    });
                  } else {
                    $('#' + e.target.id).html(content);
                  }

                  $('#technical-xyz .tech').each(function (index, obj) {
                    //	$(this).parent('a').remove();
                    $(this).parent().attr({
                      'href': $(this).attr('src').replace('_thumb.jpg', '.png'),
                      'data-lightbox': 'dex-image'
                    });
                  });
                }
              }, "json")
              .fail(function () {
                ajax_fail(jqxhr);
              });
          }

        });


        // Reusable generic function
        var traverseElement = function (el, regex, textReplacerFunc, descr) {
          // script and style elements are left alone
          //console.log(descr);
          //return;

          //var child = el.lastChild;
          var child = el.children().last();
          //console.log(child[0].nodeType);
          while (child.length) {
            //	console.log(child[0].nodeType);
            //	console.log(child);
            //if (child[0].nodeType == 1) {
            //if (/^(div|table|tr)$/i.test(child[0].tagName)) {
            if (!/^(p|td)$/i.test(child[0].tagName)) {
              traverseElement(child, regex, textReplacerFunc, descr);
            } else {
              textReplacerFunc(child, regex, descr);
            }

            //	if(child.length)
            //textReplacerFunc(child, regex);
            child = child.prev();
            //console.log(child);
          }
          //return

        };

        // This function does the replacing for every matched piece of text
        // and can be customized to do what you like
        var textReplacerFunc = function (elmt, regex, descr) {
          //console.log(elmt.tagName);
          if (!/^(script|style|img)$/.test(elmt.tagName)) {
            //console.log(elmt.html());
            elmt.html(elmt.html()); //.replace(regex, '<span data-toggle="tooltip" data-placement="top" title="'+descr+'" class="dtooltip">$1</span>'));
          }
          return elmt;
          //textNode.data = textNode.data.replace(regex, '<span style="color:red;">$1</span>');
          //console.log($(textNode));
        };

        // The main function
        var replaceWords = function (html, arrVals) {
          var container = $('<div/>');
          container.html(html);

          var phrase = arrVals[0].phrase;
          var descr = arrVals[0].description;
          // Replace the words one at a time to ensure each one gets matched
          //for (var i = 0, len = words.length; i < len; ++i) {
          //traverseElement(container, new RegExp('('+words[i]+')', "gim"), textReplacerFunc);
          //	}
          traverseElement(container, new RegExp('(' + phrase + ')', "gim"), textReplacerFunc, descr);
          return container.html();
        };



        /*
         $('.tech').parent().zoom({
         on:'grab',
         callback: function(){
         var techImg = $(this).parent().find('.tech')[0].currentSrc;
         if($.inArray(techImg, arrTechs) == -1)
         $(this).attr('src', techImg.replace('_thumb.jpg', '.png'));
         arrTechs.push(techImg);
         }
         }
         );
         */
        /* $('.dzoom').parent().zoom({
         on:'grab',
         callback: function(){
         var zImg = $(this).parent().find('.dzoom')[0].currentSrc;
         if($.inArray(zImg, arrDzooms) == -1)
         $(this).attr('src', zImg.replace('-400x400', ''));
         arrDzooms.push(zImg);
         }
         }
         );*/
      }
    },
    'services': {
      init: function () {
        var wow = new WOW({
          animateClass: 'animated',
          offset: 100,
          callback: function (box) {
            //console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
          }
        });
        setTimeout(function () {
          wow.init();
        }, 2000);


        $('a[href*=#]:not([href=#]).next, a[href*=#]:not([href=#]).down').click(function () {
          var target;
          if ($(window).width() >= 768) {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top - 130
                }, 1000);
                return false;
              }
            }
          } else {
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top - 68
                }, 1000);
                return false;
              }
            }
          }
        });

      }
    },
    'search': {
      init: function () {
        $('button[class*="vm-"]').click(function (e) {
          var section = $('.' + $(this).attr('data-value'));
          e.preventDefault();
          $this = $(this);
          if (section.is(':hidden')) {
            section.slideDown('slow', function () {
              $this.html('<i class="fa fa-arrow-up"></i> Close');
            });
          } else {
            section.slideUp('slow', function () {
              $this.html('<i class="fa fa-arrow-down"></i> View More');
            });
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        //console.log(classnm);
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  //###############################
  ajax_fail = function (jqxhr) {
    var strErr = 'ERROR: \n\n     Err code: ' + jqxhr.responseText + '\n     Status: ' + jqxhr.statusText;
    console.error(strErr);
    //alert(strErr);
    return false;
  };

  //#################################
  build_pdf_bsk = function () {
    var data = {
      action: 'get_pdf_basket'
    };
    var jqxhr = $.post('/wp-admin/admin-ajax.php', data, function (resp) {
        //console.log(resp);
        if (resp.resp == 'OK') {
          $('#pdfBasket').html(resp.HTML);
          if (resp.HTML === '') {
            $('.cd-panel').removeClass('is-visible');
            $('#pdfEncForm').hide();
            $('#btnSubEnc').show();
            $('#pdfBskBtn').hide();
          } else {
            $('#pdfBskBtn').show();
          }
        }
      }, "json")
      .fail(function () {
        ajax_fail(jqxhr);
      });

    //console.log(arrBsk);
    /*var HTML = '';
     var arrIDs = [];
     var count = 0;
     if(arrBsk) {
     $.each(arrBsk, function (index, value) {
     count++;
     arrMob = value.split(':');
     arrIDs.push(arrMob[0]);
     HTML += '<li><span data-value="' + value + '" class="glyphicon glyphicon-remove bskRm"></span> ' +
     '<a href="/product_pdfs/'+arrMob[2].capitalize()+'-'+arrMob[1].replace(' ', '-')+'.pdf" target="_blank">' + arrMob[1] + '</a></li>';
     });
     }
     $('.cd-panel-content').html(HTML);
     */

    //	if(count > 1)
    //		$('#compButt').html('<a href="/compare/?cmp='+arrIDs.join(',')+'" class="btn btn-primary btn-xs btn-block">View Compare List</a>');
    //	else
    //		$('#compButt').html('');
  };

})(jQuery); // Fully reference jQuery after this point.

// front-page color change on scroll
(function ($) {
  //$('.colour').scrollSections({alwaysStartWithFirstSection: true});
  /*$.scrollify({
   section : ".colour",
   });*/
  /*	var wHeight = $(window).height();
   $('.colour')
   .scrollie({
   direction: 'both',
   scrollOffset : -wHeight/2,
   scrollRatio : 2,
   scrollingInView : function(elem, offset, direction, coords) {
   var bgColor = elem.data('background');
   $('body').css('background-color', bgColor);
   }
   });*/
})(jQuery);
